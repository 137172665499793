import React from 'react';
import { useNavigate } from 'react-router-dom';

function Rooms() {
  const navigate = useNavigate();

  return <>
      <button onClick={() =>  navigate('/main')}>Rooms</button>
  </>;
}

export default Rooms;
