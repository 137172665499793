// import React from 'react';
// import { QrCode } from 'lucide-react';

// function ShowInfo() {
//   return (
//     <div className="show-info">
//       <div className="show-info-content">
//         <div className="show-info-details">
//           <p className="show-info-detail">Show: First Dates Hotel</p>
//           <p className="show-info-detail">Date: 13-04-2022</p>
//           <p className="show-info-detail">Time: 15:30 - 16:45</p>
//         </div>
//         <div className="show-info-share">
//           <div className="qr-code-container">
//           <p className="show-info-invite">Share the QR code or URL to invite guests to join the watch group session.</p>
//             <div>
//                 <QrCode size={120} />
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default ShowInfo;


// api data
// import React, { useEffect, useState } from "react";
// import QRCode from "qrcode";

// const generateRandomString = (length) => {
//   const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
//   let result = '';
//   for (let i = 0; i < length; i++) {
//     const randomIndex = Math.floor(Math.random() * characters.length);
//     result += characters.charAt(randomIndex);
//   }
//   return result;
// };

// function ShowInfo({ roomDetail,socket }) {
//   const [qrCodeImageUrl, setQrCodeImageUrl] = useState(null);
//   const [tvConnectionData, setTVConnectionData] = useState(null);
//   const [qrCodeUrl, setQrCodeUrl] = useState();
//   const [showtempdata, setShowtempdata] = useState("");

//   const serverUrl = "http://localhost:3000";
//   const conv = "/" + generateRandomString(6);
//   const Joinedurl = serverUrl + conv;


//      useEffect(() => {
//         console.log("useEffect running...");
//         const generateQRCode = (token, sessionId) => {
//           const additionalData = {
//             macId: "BB-21-4A-9F-20-C7",
//             room_id: {
//               roomId: "93a2423f-b06e-43ca-8e76-244fa4e5a819",
//               isExpired: false,
//               showTime: {
//                 tile: "Mirette ermittelt",
//                 start: "1670322000000",
//                 end: "1670322600000"
//               }
//             },
//             type: "host_event"
//           };
    
//           const url = `${serverUrl}/#/joinevent?token=${token}&sessionId=${sessionId}&joinedurl=${Joinedurl}&data=${encodeURIComponent(JSON.stringify(additionalData))}`;
//           setShowtempdata(url);
    
//           // const urltemp = `${serverUrl}/#/joinevent?token=${token}&sessionId=${sessionId}&joinedurl=${Joinedurl}`;
//           QRCode.toDataURL(url, (err, qrCodeUrl) => {
//             if (err) {
//               console.error('Error generating QR code:', err);
//               return;
//             }
//             setQrCodeImageUrl(qrCodeUrl);
//             console.log("qrcodeurl show room", qrCodeUrl);
//             socket.emit('qrCodeScanned', { data: url });
//           });
//         };
    
//         const generateTokenAndSessionId = () => {
//           const token = generateRandomString(10);
//           const sessionId = generateRandomString(10);
//           return { token, sessionId };
//         };
    
//         const { token, sessionId } = generateTokenAndSessionId();
//         generateQRCode(token, sessionId);
    
//       }, [socket]);


//   useEffect(() => {
//     if (roomDetail?.qrUrl) {
//       QRCode.toDataURL(roomDetail.qrUrl)
//         .then((qrCodeUrl) => {
//           setQrCodeImageUrl(qrCodeUrl);
//         })
//         .catch((err) => {
//           console.error("Error generating QR code:", err);
//         });
//     }
//   }, [roomDetail?.qrUrl]);

//   return (
//     <div className="show-info">
//       <div className="show-info-content">
//         <div className="show-info-details">
//           {roomDetail ? (
//             <>
//               <p className="show-info-detail">Room Name: {roomDetail.roomName}</p>
//               <p className="show-info-detail">Host User: {roomDetail.hostUserId}</p>
//               <p className="show-info-detail">
//                 Max Participants: {roomDetail.maxParticipants}
//               </p>
//               <p className="show-info-detail">
//                 Created At: {new Date(roomDetail.createdAt).toLocaleString()}
//               </p>
//             </>
//           ) : (
//             <p>Loading room details...</p>
//           )}
//         </div>
//         <div className="show-info-share">
//           <div className="qr-code-container">
//             <p className="show-info-invite">
//               Share the QR code or URL to invite guests to join the watch group session.
//             </p>
//             {qrCodeImageUrl ? (
//               <>
//               <img
//                 src={qrCodeImageUrl}
//                 alt="QR Code"
//                 style={{ width: "120px", height: "120px" }}
//               />
//               <p>{showtempdata}</p>
//               </>

              
//             ) : (
//               <p>Generating QR Code...</p>
//             )}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default ShowInfo;



//new code
import React, { useEffect, useState } from "react";
import QRCode from "qrcode";

const generateRandomString = (length) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }
  return result;
};

function ShowInfo({ roomDetail, socket }) {
  const [qrCodeImageUrl, setQrCodeImageUrl] = useState(null);
  const [tvConnectionData, setTVConnectionData] = useState(null);
  const [qrCodeUrl, setQrCodeUrl] = useState();
  const [showtempdata, setShowtempdata] = useState("");

  // const serverUrl = "http://localhost:3000";
  const serverUrl = process.env.REACT_APP_SOCKET_URL;
  const conv = "/" + generateRandomString(6);
  const Joinedurl = serverUrl + conv;

  useEffect(() => {
    console.log("useEffect running...");
    const generateQRCode = (token, sessionId) => {
      const additionalData = {
        macId: "8C-21-4A-9F-20-C1",
        room_id: {
          roomId: "93a2423f-b06e-43ca-8e76-244fa4e5a819",
          isExpired: false,
          showTime: {
            title: "Mirette ermittelt3",
            start: "1670322000000",
            end: "1670322600000"
          }
        },
        type: "joined"
      };

      const url = `${serverUrl}/#/joinevent?token=${token}&sessionId=${sessionId}&joinedurl=${Joinedurl}&data=${encodeURIComponent(JSON.stringify(additionalData))}`;
      setShowtempdata(url);

      QRCode.toDataURL(url, (err, qrCodeUrl) => {
        if (err) {
          console.error('Error generating QR code:', err);
          return;
        }
        setQrCodeImageUrl(qrCodeUrl);
        console.log("qrcodeurl show room", qrCodeUrl);
        socket.emit('qrCodeScanned', { data: url });
      });
    };

    const generateTokenAndSessionId = () => {
      const token = generateRandomString(10);
      const sessionId = generateRandomString(10);
      return { token, sessionId };
    };

    const { token, sessionId } = generateTokenAndSessionId();
    generateQRCode(token, sessionId);

  }, [socket]);


        useEffect(() => {
          socket.on("TVconnected", function (response) {
            console.log("Socket response show info:", response);
            // Store the response data in the state with the proper format
            setTVConnectionData(response.data);
          });
        }, [socket]);

  useEffect(() => {
    if (roomDetail?.qrUrl) {
      QRCode.toDataURL(roomDetail.qrUrl)
        .then((qrCodeUrl) => {
          setQrCodeImageUrl(qrCodeUrl);
        })
        .catch((err) => {
          console.error("Error generating QR code:", err);
        });
    }
  }, [roomDetail?.qrUrl]);

  const handleCopyUrl = () => {
    navigator.clipboard.writeText(showtempdata)
      .then(() => {
        alert('URL copied to clipboard!');
      })
      .catch((err) => {
        console.error('Failed to copy URL: ', err);
      });
  };

  return (
    <div className="show-info">
      <div className="show-info-content">
        <div className="show-info-details">
          {roomDetail ? (
            <>
              <p className="show-info-detail">Room Name: {roomDetail.roomName}</p>
              <p className="show-info-detail">Host User: {roomDetail.hostUserId}</p>
              <p className="show-info-detail">
                Max Participants: {roomDetail.maxParticipants}
              </p>
              <p className="show-info-detail">
                Created At: {new Date(roomDetail.createdAt).toLocaleString()}
              </p>
            </>
          ) : (
            <p>Loading room details...</p>
          )}
        </div>
        <div className="show-info-share">
          <div className="qr-code-container">
            <p className="show-info-invite">
              Share the QR code or URL to invite guests to join the watch group session.
            </p>
            {qrCodeImageUrl ? (
              <>
                <img
                  src={qrCodeImageUrl}
                  alt="QR Code"
                  style={{ width: "120px", height: "120px" }}
                />
                {/* <p>{showtempdata}</p> */}
                <button onClick={handleCopyUrl}>Copy URL</button>
              </>
            ) : (
              <p>Generating QR Code...</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShowInfo;