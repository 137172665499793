// import React, { useState, useEffect } from "react";
// import { BrowserQRCodeReader } from "@zxing/library";
// import { useTranslation } from '../utils/TranslationContext'; 
// import { decodeString } from '../utils/encryptionUtil';
// import { getParameterValue } from '../utils/mainUtil';
// import { Share } from 'lucide-react'; // Import Share icon from lucide-react

// export default function EventRoomJoinedCam({ socket }) {
//   const { t } = useTranslation();
//   const [cameraEnabled, setCameraEnabled] = useState(false);
//   const [copySuccess, setCopySuccess] = useState(false); // State for copy success feedback
//   let tokenhost;
//   let tokenjoinee;
//   let chaturl;
//   let channelId = null;
//   let programInfo = null;
//   const urlwithtoken = window.location.href;
//   const urltoken = /token=([a-zA-Z0-9]+)/;
//   const match = urltoken.exec(urlwithtoken);
  
//   if (match && match[1]) {
//     tokenhost = match[1];
//   }

//   const joinedurllll = /joinedurl=([a-zA-Z0-9:/]+)/;
//   const match1 = joinedurllll.exec(urlwithtoken);
//   chaturl = match1 ? match1[1] : '';

//   const startIndex = urlwithtoken.indexOf('channelId=') + 'channelId='.length;
//   const encodedPart = urlwithtoken.substring(startIndex);

//   const decodedPart = decodeURIComponent(encodedPart);
//   try {
//     if (decodedPart) {
//       channelId = decodeString(decodedPart);
//       programInfo = getParameterValue('programInfo');
//     }
//   } catch (error) {
//     console.error("Error parsing channelId:", error.message);
//     channelId = null;
//     programInfo = null;
//   }

//   useEffect(() => {
//     if (cameraEnabled) {
//       const codeReader = new BrowserQRCodeReader();
//       const previewElem = document.querySelector("#WatchpartyUjjwal > video");

//       async function startScanner() {
//         try {
//           const controls1 = await codeReader.decodeOnceFromVideoDevice(
//             undefined,
//             previewElem
//           );
//           alert(controls1.text);
//           const tokenPattern = /token=([a-zA-Z0-9]+)/;
//           const match = tokenPattern.exec(controls1.text);
//           if (match && match[1]) {
//             tokenjoinee = match[1];
//           }
//           const mainaddedtoken = {
//             host: tokenhost,
//             joins: tokenjoinee,
//             conversions: chaturl,
//             channelId: channelId,
//             GetprogramInfo: programInfo
//           };
//           socket.emit("TVconnectedEvent", { data: mainaddedtoken });
//         } catch (error) {
//           console.log(error);
//         }
//       }

//       startScanner();
//       return () => {
//         codeReader.reset();
//       };
//     }
//   }, [cameraEnabled]);

//   // Function to copy link to clipboard
//   const handleCopyLink = () => {
//     navigator.clipboard.writeText(urlwithtoken).then(() => {
//       setCopySuccess(true);
//       setTimeout(() => setCopySuccess(false), 2000); // Reset copy success after 2 seconds
//     }).catch((error) => {
//       console.error("Copy failed!", error);
//     });
//   };

//   // Function to handle share button click (You can add your custom share logic here)
//   const handleShareLink = () => {
//     if (navigator.share) {
//       navigator.share({
//         title: 'Join Watch Room',
//         url: urlwithtoken,
//       }).catch((error) => console.error('Error sharing:', error));
//     } else {
//       console.log('Web share not supported');
//     }
//   };

//   return (
//     <div className="container">
//       <div className="row min-vh-100 justify-content-center">
//         <div className="col-12 text-center"></div>
//         <div
//           className="col-md-8 col-lg-6 col-xl-4 text-center"
//           style={{ marginTop: "-5%", marginBottom: "3%" }}
//         >
//           <div className="card col-xl-4">
//             <h5>Welcome Room Joined -- Event</h5>
//             <div style={{ fontWeight: "bold", fontSize: "12px" }}>
//               <p>{t('Follow these steps')}:</p>
//               <ol style={{ textAlign: "justify" }}>
//                 <li>{t('Go to your TV')}.</li>
//                 <li>{t('Launch the Watch Room app')}.</li>
//                 <li>{t('Scan the QR code')}.</li>
//               </ol>
//             </div>

//             <div className="card-body text-center">
//               <div id="WatchpartyUjjwal">
//                 <video width="300" height="300" />
//               </div>
//               {!cameraEnabled && (
//                 <button 
//                   onClick={() => setCameraEnabled(true)} 
//                   className="btn btn-primary mt-3"
//                 >
//                   Open Camera
//                 </button>
//               )}
//               {/* <button 
//                 onClick={handleCopyLink} 
//                 className="btn btn-secondary mt-3"
//               >
//                 Copy Link
//               </button> */}
//               <br/>
//               {copySuccess && <p style={{ color: 'green' }}>Link copied to clipboard!</p>}
//               <button 
//                 onClick={handleShareLink} 
//                 className="btn btn-info mt-3"
//               >
//                 <Share size={20} /> {t('Share Link')}
//               </button>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }



//test
import React, { useEffect } from 'react';
import { BrowserQRCodeReader } from '@zxing/library';

export default function EventRoomJoinedCam(props) {
    const { socket } = props;
  console.log('Current URL:', window.location.href);
  let tokenhost;
  let tokenjoinee;
  let chaturl;
  const urlwithtoken = window.location.href;

  const urltoken = /token=([a-zA-Z0-9]+)/;
  const match = urltoken.exec(urlwithtoken);
  console.log("sassssssss", match);
  if (match && match[1]) {
    tokenhost = match[1];
    console.log('Extracted Token from url of host:', tokenhost);
  } else {
    console.log('Token not found in the log text.');
  }

  const joinedurllll = /joinedurl=([a-zA-Z0-9:/]+)/;
  const match1 = joinedurllll.exec(urlwithtoken);
  chaturl = match1[1];
  console.log("chaturl", chaturl);

  useEffect(() => {
    const codeReader = new BrowserQRCodeReader();
    const previewElem = document.querySelector("#sameer > video");

    async function startScanner() {
      try {
        const controls1 = await codeReader.decodeOnceFromVideoDevice(undefined, previewElem);
        console.log("hellllllll", controls1);
        alert(controls1.text);

        const tokenPattern = /token=([a-zA-Z0-9]+)/;
        const match = tokenPattern.exec(controls1.text);
        if (match && match[1]) {
          tokenjoinee = match[1];
          console.log('Extracted Token:', tokenjoinee);
        } else {
          console.log('Token not found in the log text.');
        }

        const dataPattern = /data=([^&]+)/;
        const dataMatch = dataPattern.exec(controls1.text);
        let additionalData = {};
        if (dataMatch && dataMatch[1]) {
          additionalData = JSON.parse(decodeURIComponent(dataMatch[1]));
          console.log('Extracted Additional Data:', additionalData);
        }

        const mainaddedtoken = {
          host: tokenhost,
          joins: tokenjoinee,
          conversions: chaturl,
          additionalData: additionalData // Include the additional data
        };
        console.log("total token", mainaddedtoken);

        socket.emit('TVconnected', { data: mainaddedtoken });

      } catch (error) {
        console.log(error);
      }
    }

    startScanner();

    return () => {
      codeReader.reset();
    };
  }, []);

  return (
    <>
      <p>Scan the join QR code to join</p>
      <p>this is {tokenhost}</p>
      <p>see up</p>
      <div id="sameer">
        <video></video>
      </div>
      <p>see down</p>
    </>
  );
}