// // import React from 'react';
// // import { Link } from 'react-router-dom';

// // function Event() {
// //   return (
// //     <div>
// //       <h1>Event Page</h1>
// //       <ul>
// //         <li><Link to="/event/tvguid">Tvguid</Link></li>
// //         <li><Link to="/event/tvguid/channelinfo">Channel Info</Link></li>
// //         <li><Link to="/event/rooms">Rooms</Link></li>
// //       </ul>
// //     </div>
// //   );
// // }

// // export default Event;

// import React, { useState, useEffect, useRef } from "react";
// import axios from "axios";
// import { useNavigate } from "react-router-dom";
// import { CirclePlus } from 'lucide-react';
// import QRCode from "qrcode";
// import "./WatchRoom.css";

// const WatchRoom = () => {
//   const [qrCodeUrl, setQrCodeUrl] = useState("");
//   const [rooms, setRooms] = useState([]);
//   const [JoinedRooms, setJoinedRooms] = useState([]);
//   const [usernameid, setUsernameid] = useState([]);
//   const [isImageLoaded, setIsImageLoaded] = useState(false); // Manage background image state
//   const [dateTime, setDateTime] = useState("");

//   const [tempdata, settempdata] = useState()
//   const roomRefs = useRef([]);

//   const navigate = useNavigate()
//   // useEffect(() => {
//   //   // Generate QR Code
//   //   QRCode.toDataURL("https://example.com")
//   //     .then((url) => setQrCodeUrl(url))
//   //     .catch((err) => console.error("Error generating QR code", err));
//   // }, []);

//   const formatDateTime = () => {
//     const now = new Date();
//     const hours = now.getHours().toString().padStart(2, "0");
//     const minutes = now.getMinutes().toString().padStart(2, "0");
//     const day = now.toLocaleString("en-US", { weekday: "short" });
//     const date = now.getDate().toString().padStart(2, "0");
//     const month = now.toLocaleString("en-US", { month: "short" });
//     const year = now.getFullYear();

//     return `${hours}:${minutes} ${day} ${date} ${month} ${year}`;
//   };


//   // useEffect(() => {
//   // const storeUserInfo = JSON.parse(localStorage.getItem("userInfo"));
//   //   // Generate QR Code
//   //   QRCode.toDataURL("https://example.com")
//   //     .then((url) => setQrCodeUrl(url))
//   //     .catch((err) => console.error("Error generating QR code", err));

//   //   // Fetch watch rooms data
//   //   fetch("https://watchparty.tpvai.com/b/api/getWatchRooms")
//   //     .then((response) => response.json())
//   //     .then((data) => {
//   //       // setRooms(data); 
//   //       setRooms(storeUserInfo)

//   //     })
//   //     .catch((error) => console.error("Error fetching rooms:", error));
//   // }, []);
//   // console.log("rooms----------->",rooms)
  
  
//   //new
//   useEffect(() => {
//     // Retrieve stored user information from localStorage
  
  
//     // Generate QR Code (if needed)
//     const serverUrl = process.env.REACT_APP_WEB_URL;
//     const generateRandomString = (length) => {
//       const characters =
//         "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
//       let result = "";
//       for (let i = 0; i < length; i++) {
//         const randomIndex = Math.floor(Math.random() * characters.length);
//         result += characters.charAt(randomIndex);
//       }
//       return result;
//     };
//     const generateTokenAndSessionId = () => {
//       const token = generateRandomString(10);
//       const sessionId = generateRandomString(10);
//       return { token, sessionId };
//     };

//     const { token, sessionId } = generateTokenAndSessionId();
//     const Joinedurl = "https://wp.tpvai.com"
//     const tvProgramInfoCall = {
//       _id: 5761,
//       eventid: 6744,
//       presetid: 17,
//       eventname: "NCIS",
//       version: 0,
//       starttime: 1670244600,
//       endtime: 1670248800,
//       shortinfo: null,
//       extendedinfo: "Le tueur de port en port frappe à nouveau, s'attaquant cette fois à Danny Price, l'ancien coéquipier de DiNozzo lorsqu'il était à la brigade criminelle de Baltimore. Ce dernier se remémore alors son passé, notamment sa première rencontre avec Gibbs.",
//       languageid: 27,
//       linktype: null,
//       targetindex: null,
//       genre: "GENRE_MOVIE",
//       scrambled: 0,
//       rating: null,
//       guidanceinfo: null,
//       videoformat: 2
//     };
//     const TVCall = "content://android.media.tv/channel/61"

//     const url = `${serverUrl}/#/joinevent?token=${token}&sessionId=${sessionId}&joinedurl=${Joinedurl}&programInfo=${tvProgramInfoCall}&channelId=${TVCall}`;
//     QRCode.toDataURL(url)
//       .then((url) => setQrCodeUrl(url))
//       .catch((err) => console.error("Error generating QR code", err));
//   }, []);
  
  
//   // console.log("rooms----------->1,",storedEmail)
//   useEffect(() => {
//     const img = new Image();
//     img.src = '../../public/images/view-3d-cinema-theatre-room.jpg';
//     img.onload = () => {
//       setIsImageLoaded(true);
//     };
//     const storedEmail = localStorage.getItem('userId');
//     const username = storedEmail.split("@")[0];
//     setUsernameid(username);
//   }, []);

//   function fetchAndStoreUserInfo() {
//     const macId = localStorage.getItem('macId');
//     // const macId = "8C-21-4A-9F-20-CC";
//     const localStorageKey = "userInfo";
//       axios
//         .get(`https://watchparty.tpvai.com/b/api/getuser/${macId}`)
//         .then((response) => {
//           const getuser = response.data.data;
//           localStorage.setItem(localStorageKey, JSON.stringify(getuser));
//           const storeUserInfo = JSON.parse(localStorage.getItem("userInfo"));
//           if (storeUserInfo) {
//             // Use `storeUserInfo.host_event` to populate the rooms
//             setRooms(storeUserInfo.host_event || []);
//             setJoinedRooms(storeUserInfo.joined || []);
//           } else {
//             console.error("No user info found in localStorage");
//           }
//         })
//         .catch((error) => {
//           console.error("Failed to fetch user info:", error);
//         });
  
//   }


//   useEffect(() => {
//     // Set the initial dateTime
//     setDateTime(formatDateTime());
//     fetchAndStoreUserInfo()
//     // Update the time every second
//     const interval = setInterval(() => {
//       setDateTime(formatDateTime());
//     }, 1000);

//     // Cleanup the interval on component unmount
//     return () => clearInterval(interval);
//   }, []);

//   const handleKeyDown = (event, index,roomId) => {
//     if (event.key === "ArrowRight") {
//       roomRefs.current[index + 1]?.focus();
//     } else if (event.key === "ArrowLeft") {
//       roomRefs.current[index - 1]?.focus();
//     } else if (event.key === "Enter") {
//       // alert(`Selected Room: Room ${index + 1}`);
//       navigate("/event/rooms",{ state: { roomId } });
//     }
//   };


//   const handleClick = () => {
//     navigate("/event/tvguid");
//   };

//   return (
//     // <div className="app">
//     <div className={`app ${isImageLoaded ? "high-quality" : "low-quality"}`}>
//       {/* Header Section */}
//       <header className="header">
//         <div className="logo-container">
//           <div className="logo">
//             <span>R</span>
//           </div>
          
//         </div>
//       </header>

//       {/* Sidebar */}
//       <div className="sidebar-watch">
//         <div className="sidebar-content-watch">
//           {/* My Rooms Section */}
//           <div className="sidebar-section-watch">
//             <h3>My Rooms</h3>
//             <button className="tv-guide-btn" onClick={handleClick}>
//               <span className="menu-icon">≡</span>
//               <span>TV Guide</span>
//             </button>
//             <p className="guide-text">
//               Add a room from TV Guide via Options or Programme info
//             </p>
//           </div>

//           {/* QR Code Section */}
//           <div className="qr-section">
//             <h3>Joined Rooms</h3>
//             {qrCodeUrl ? (
//               <img
//                 src={qrCodeUrl}
//                 alt="QR Code"
//                 style={{ width: "100px", height: "100px" }}
//               />
//             ) : (
//               <p>Loading QR Code...</p>
//             )}
//             <p className="guide-text">Scan to join a room from invitation</p>
//           </div>
//         </div>
//       </div>
//       {/* <div className="main-content-grid">
//         <div className="my-rooms">
          

// {rooms.map((room, index) => {
//   const showTime = room.showTime?.[0] || {};
//   const title = showTime.title || "806 First Dates Hotel";

//   // Convert start and end times to Date objects
//   const startTime = new Date(parseInt(showTime.start_time_utc_millis || "0"));
//   const endTime = new Date(parseInt(showTime.end_time_utc_millis || "0"));

//   // Format date and time
//   const formattedTimeRange = `${[
//     String(startTime.getDate()).padStart(2, "0"),
//     String(startTime.getMonth() + 1).padStart(2, "0"),
//     startTime.getFullYear(),
//   ].join("-")} ${startTime.toTimeString().slice(0, 5)} - ${endTime.toTimeString().slice(0, 5)}`;

//   // Format short date for display
//   const formattedShortDate = startTime.toLocaleDateString("en-GB", {
//     day: "2-digit",
//     month: "short",
//   });

//   return (
//     <div className="room-container" key={room.id || index}>
//       <div
//         className="room-card my-room-card"
//         tabIndex={0}
//         ref={(el) => (roomRefs.current[index] = el)}
//         onKeyDown={(event) => handleKeyDown(event, index, room.roomId)}
//       >
//         <p className="room-title">{title}</p>
//         <p className="room-time">{formattedTimeRange || "13-04-2022 15:30 - 16:45"}</p>
//       </div>
//       <p className="short-date">{formattedShortDate || "13 Apr"}</p>
//     </div>
//   );
// })}




          

          
//         </div>

//         <div className="joined-rooms">
           
// {rooms.map((room, index) => {
//   const showTime = room.showTime?.[0] || {};
//   const title = showTime.title || "806 First Dates Hotel";

//   // Convert start and end times to Date objects
//   const startTime = new Date(parseInt(showTime.start_time_utc_millis || "0"));
//   const endTime = new Date(parseInt(showTime.end_time_utc_millis || "0"));

//   // Format date and time
//   const formattedTimeRange = `${[
//     String(startTime.getDate()).padStart(2, "0"),
//     String(startTime.getMonth() + 1).padStart(2, "0"),
//     startTime.getFullYear(),
//   ].join("-")} ${startTime.toTimeString().slice(0, 5)} - ${endTime.toTimeString().slice(0, 5)}`;

//   // Format short date for display
//   const formattedShortDate = startTime.toLocaleDateString("en-GB", {
//     day: "2-digit",
//     month: "short",
//   });

//   const isExpired = room.isExpired; // Use room.isExpired from API response

//   return (
//     <div
//       className={`room-container ${isExpired ? "disabled-room" : ""}`}
//       key={room.id || index}
//     >
//       <div
//         className={`room-card my-room-card ${isExpired ? "disabled" : ""}`}
//         tabIndex={isExpired ? -1 : 0} // Prevent focus on expired rooms
//         ref={(el) => (roomRefs.current[index] = el)}
//         onKeyDown={(event) => !isExpired && handleKeyDown(event, index, room.roomId)}
//         style={{ pointerEvents: isExpired ? "none" : "auto", opacity: isExpired ? 0.5 : 1 }}
//       >
//         <p className="room-title">{title}</p>
//         <p className="room-time">{formattedTimeRange || "13-04-2022 15:30 - 16:45"}</p>
//       </div>
//       <p className="short-date">{formattedShortDate || "13 Apr"}</p>
//     </div>
//   );
// })}


//         </div>
//       </div> */}

// <div className="main-content-grid">
//   {/* {rooms.length === 0 ? ( */}
//   {rooms.length === 0 && JoinedRooms.length === 0 ? ( //temp should delete
//     <div className="welcome-container">
//   <div className="w-title">Welcome {usernameid}!</div>
//   <p className="welcome-message">
//     You may create a Watch room and invite your guests to join a watch session. Or simply join one.
//   </p>
//   <p className="welcome-requirements">
//     A Philips TV with Watch Room feature and Internet are required.
//   </p>
// </div>

//   ) : (
//     <>
//       <div className="my-rooms">
//         {/* {Array.from({ length: 3 }).map((_, index) => (
//           <div className="room-container" key={index}>
//             <div
//               className="room-card my-room-card"
//               tabIndex={0}
//               ref={(el) => (roomRefs.current[index] = el)}
//               onKeyDown={(event) => handleKeyDown(event, index)}
//             >
//               <p className="room-title">Room {index + 1}</p>
//               <p className="room-time">13-04-2022 15:30 - 16:45</p>
//             </div>
//             <p className="short-date">13 Apr</p>
//           </div>
//         ))} */}
//         {rooms.map((room, index) => {
//     const title = room.showTime?.title || "Default Room Title"; // Use the `tile` field for the title
//     const startTime = new Date(parseInt(room.showTime?.start || "0"));
//     const endTime = new Date(parseInt(room.showTime?.end || "0"));

//     const formattedTimeRange = `${[
//       String(startTime.getDate()).padStart(2, "0"),
//       String(startTime.getMonth() + 1).padStart(2, "0"),
//       startTime.getFullYear(),
//     ].join("-")} ${startTime.toTimeString().slice(0, 5)} - ${endTime.toTimeString().slice(0, 5)}`;

//     const formattedShortDate = startTime.toLocaleDateString("en-GB", {
//       day: "2-digit",
//       month: "short",
//     });

//     return (
//       <div className="room-container" key={room._id || index}>
//         <div
//           className="room-card my-room-card"
//           tabIndex={0}
//           ref={(el) => (roomRefs.current[index] = el)}
//           onKeyDown={(event) => handleKeyDown(event, index, room.roomId)}
//         >
//           <p className="room-title">{title}</p>
//           <p className="room-time">{formattedTimeRange}</p>
//         </div>
//         <p className="short-date">{formattedShortDate}</p>
//       </div>
//     );
//   })}
//       </div>
//       {/* <div className="joined-rooms">
//         {rooms.map((room, index) => {
//           const showTime = room.showTime?.[0] || {};
//           const title = showTime.title || "806 First Dates Hotel";

//           const startTime = new Date(parseInt(showTime.start_time_utc_millis || "0"));
//           const endTime = new Date(parseInt(showTime.end_time_utc_millis || "0"));

//           const formattedTimeRange = `${[
//             String(startTime.getDate()).padStart(2, "0"),
//             String(startTime.getMonth() + 1).padStart(2, "0"),
//             startTime.getFullYear(),
//           ].join("-")} ${startTime.toTimeString().slice(0, 5)} - ${endTime.toTimeString().slice(0, 5)}`;

//           const formattedShortDate = startTime.toLocaleDateString("en-GB", {
//             day: "2-digit",
//             month: "short",
//           });

//           const isExpired = room.isExpired;

//           return (
//             <div
//               className={`room-container ${isExpired ? "disabled-room" : ""}`}
//               key={room.id || index}
//             >
//               <div
//                 className={`room-card my-room-card ${isExpired ? "disabled" : ""}`}
//                 tabIndex={isExpired ? -1 : 0}
//                 ref={(el) => (roomRefs.current[index] = el)}
//                 onKeyDown={(event) =>
//                   !isExpired && handleKeyDown(event, index, room.roomId)
//                 }
//                 style={{
//                   pointerEvents: isExpired ? "none" : "auto",
//                   opacity: isExpired ? 0.5 : 1,
//                 }}
//               >
//                 <p className="room-title">{title}</p>
//                 <p className="room-time">{formattedTimeRange || "13-04-2022 15:30 - 16:45"}</p>
//               </div>
//               <p className="short-date">{formattedShortDate || "13 Apr"}</p>
//             </div>
//           );
//         })}
//       </div> */}
//       <div className="joined-rooms">
//   {JoinedRooms.map((room, index) => {
//     const title = room.showTime?.title || "Default Room Title"; // Use the `tile` field for the title
//     const startTime = new Date(parseInt(room.showTime?.start || "0"));
//     const endTime = new Date(parseInt(room.showTime?.end || "0"));

//     const formattedTimeRange = `${[
//       String(startTime.getDate()).padStart(2, "0"),
//       String(startTime.getMonth() + 1).padStart(2, "0"),
//       startTime.getFullYear(),
//     ].join("-")} ${startTime.toTimeString().slice(0, 5)} - ${endTime.toTimeString().slice(0, 5)}`;

//     const formattedShortDate = startTime.toLocaleDateString("en-GB", {
//       day: "2-digit",
//       month: "short",
//     });

//     return (
//       <div className="room-container" key={room._id || index}>
//         <div
//           className="room-card my-room-card"
//           tabIndex={0}
//           ref={(el) => (roomRefs.current[index] = el)}
//           onKeyDown={(event) => handleKeyDown(event, index, room.roomId)}
//         >
//           <p className="room-title">{title}</p>
//           <p className="room-time">{formattedTimeRange}</p>
//         </div>
//         <p className="short-date">{formattedShortDate}</p>
//       </div>
//     );
//   })}
// </div>

//     </>
//   )}
// </div>

//       <div className="footer">
//         <span ></span>
//         <button style={{
//           color: '#b3b3b3',
//           border: 'none',
//           background: 'none',
//           cursor: 'pointer',
//           display: 'flex',
//           alignItems: 'center',
//           gap: '0.5rem',
//           fontSize:'smaller'
//         }}>
//          {dateTime} <button class="plus-button"></button>
//          OPTIONS
//         </button>
//       </div>
//     </div>
//   );
// };

// export default WatchRoom;



// test
// import React, { useEffect, useState } from 'react';
// import * as QRCode from 'qrcode';
// import { useNavigate } from 'react-router-dom';

// const generateRandomString = (length) => {
//   const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
//   let result = '';
//   for (let i = 0; i < length; i++) {
//     const randomIndex = Math.floor(Math.random() * characters.length);
//     result += characters.charAt(randomIndex);
//   }
//   return result;
// };

// export default function WatchRoom(props) {
//   const { socket } = props;
//   const [qrCodeImageUrl, setQrCodeImageUrl] = useState();
//   const [tvConnectionData, setTVConnectionData] = useState(null);
//   const [eventData, setEventdata] = useState(null)
//   const [showMessage, setShowMessage] = useState(false);
//   const [showtempdata, setShowtempdata] = useState("");
//   const navigate = useNavigate();

//   // const serverUrl = "http://localhost:3001";
//   const serverUrl = "https://watchparty.tpvai.com";
//   const conv = "/" + generateRandomString(6);

//   const Joinedurl = serverUrl + conv;

//   useEffect(() => {
//     console.log("useEffect running...");
//     const generateQRCode = (token, sessionId) => {
//       const additionalData = {
//         macId: "BB-21-4A-9F-20-C7",
//         room_id: {
//           roomId: "93a2423f-b06e-43ca-8e76-244fa4e5a819",
//           isExpired: false,
//           showTime: {
//             tile: "Mirette ermittelt",
//             start: "1670322000000",
//             end: "1670322600000"
//           }
//         },
//         type: "host_event"
//       };

//       const url = `${serverUrl}/#/joinevent?token=${token}&sessionId=${sessionId}&joinedurl=${Joinedurl}&data=${encodeURIComponent(JSON.stringify(additionalData))}`;
//       setShowtempdata(url);
//       console.log("url", url);

//       QRCode.toDataURL(url, (err, qrCodeUrl) => {
//         if (err) {
//           console.error('Error generating QR code:', err);
//           return;
//         }
//         setQrCodeImageUrl(qrCodeUrl);
//         // console.log("qrcodeurlllllllll", qrCodeUrl);
//         socket.emit('qrCodeScanned', { data: url });
//       });
//     };

//     const generateTokenAndSessionId = () => {
//       const token = generateRandomString(10);
//       const sessionId = generateRandomString(10);
//       return { token, sessionId };
//     };

//     const { token, sessionId } = generateTokenAndSessionId();
//     console.log('token', token);
//     console.log('session', sessionId);
//     generateQRCode(token, sessionId);

//     const urlParams = new URLSearchParams(window.location.search);
//     const scannedToken = urlParams.get('token');
//     const scannedSessionId = urlParams.get('sessionId');

//     if (scannedToken && scannedSessionId) {
//       navigate('/joinscan');
//     }
//   }, [navigate, socket]);

//   useEffect(() => {
//     socket.on("TVconnected", function (response) {
//       console.log("Socket response:", response);
//       // Store the response data in the state with the proper format
//       setTVConnectionData(response.data);
//       setEventdata(JSON.stringify(response.data.additionalData, null, 2))
//       setShowMessage(true);
//     });
//   }, [socket]);

//   return (
//     <>
//       <div className="container">
//         <div className="row min-vh-100 justify-content-center align-items-center">
//           <div className="col-12 text-center">
//           </div>
//           <div className="col-md-8 col-lg-6 col-xl-4" style={{ marginTop: "-5%", marginBottom: "3%" }}>
//             <div className="card">
//               <div className="card-body text-center">
//                 <h2 className="card-text">Create/Join</h2>
//                 <img src={qrCodeImageUrl} alt="qrcode" />
//                 <p>{showtempdata}</p>
//               </div>
//             </div>
//             <div>
//               {showMessage && tvConnectionData && (
//                 <div>
//                   <h2>Host: {tvConnectionData.host}</h2>
//                   <h2>Joins: {tvConnectionData.joins}</h2>
//                   <h2>Conversions: {tvConnectionData.conversions}</h2>
//                   <pre>Convert Data:---{eventData}</pre>
//                 </div>
//               )}
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }




// sdfghjkl

// import React, { useState, useEffect, useRef } from "react";
// import axios from "axios";
// import { useNavigate } from "react-router-dom";
// import { CirclePlus } from 'lucide-react';
// import QRCode from "qrcode";
// import "./WatchRoom.css";

// const WatchRoom = ({socket}) => {
//   const [qrCodeUrl, setQrCodeUrl] = useState("");
//   const [rooms, setRooms] = useState([]);
//   const [JoinedRooms, setJoinedRooms] = useState([]);
//   const [usernameid, setUsernameid] = useState([]);
//   const [isImageLoaded, setIsImageLoaded] = useState(false); // Manage background image state
//   const [dateTime, setDateTime] = useState("");
//   const [eventData, setEventdata] = useState(null)

//   const [tempdata, settempdata] = useState()
//   const roomRefs = useRef([]);

//   const navigate = useNavigate()
//   // useEffect(() => {
//   //   // Generate QR Code
//   //   QRCode.toDataURL("https://example.com")
//   //     .then((url) => setQrCodeUrl(url))
//   //     .catch((err) => console.error("Error generating QR code", err));
//   // }, []);

//   const formatDateTime = () => {
//     const now = new Date();
//     const hours = now.getHours().toString().padStart(2, "0");
//     const minutes = now.getMinutes().toString().padStart(2, "0");
//     const day = now.toLocaleString("en-US", { weekday: "short" });
//     const date = now.getDate().toString().padStart(2, "0");
//     const month = now.toLocaleString("en-US", { month: "short" });
//     const year = now.getFullYear();

//     return `${hours}:${minutes} ${day} ${date} ${month} ${year}`;
//   };


//   useEffect(() => {
//     console.log("rrrrrrrrrrrrrr")
//     socket.on("TVconnected", function (response) {
//       console.log("Socket response:", response);
//       setEventdata(JSON.stringify(response.data.additionalData, null, 2))
//     });
//   }, [socket]);

//   // useEffect(() => {
//   // const storeUserInfo = JSON.parse(localStorage.getItem("userInfo"));
//   //   // Generate QR Code
//   //   QRCode.toDataURL("https://example.com")
//   //     .then((url) => setQrCodeUrl(url))
//   //     .catch((err) => console.error("Error generating QR code", err));

//   //   // Fetch watch rooms data
//   //   fetch("https://watchparty.tpvai.com/b/api/getWatchRooms")
//   //     .then((response) => response.json())
//   //     .then((data) => {
//   //       // setRooms(data); 
//   //       setRooms(storeUserInfo)

//   //     })
//   //     .catch((error) => console.error("Error fetching rooms:", error));
//   // }, []);
//   // console.log("rooms----------->",rooms)
  
  
//   //new
//   useEffect(() => {
//     // Retrieve stored user information from localStorage
  
  
//     // Generate QR Code (if needed)
//     const serverUrl = process.env.REACT_APP_WEB_URL;
//     const generateRandomString = (length) => {
//       const characters =
//         "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
//       let result = "";
//       for (let i = 0; i < length; i++) {
//         const randomIndex = Math.floor(Math.random() * characters.length);
//         result += characters.charAt(randomIndex);
//       }
//       return result;
//     };
//     const generateTokenAndSessionId = () => {
//       const token = generateRandomString(10);
//       const sessionId = generateRandomString(10);
//       return { token, sessionId };
//     };

//     const { token, sessionId } = generateTokenAndSessionId();
//     const Joinedurl = "https://wp.tpvai.com"
//     const tvProgramInfoCall = {
//       _id: 5761,
//       eventid: 6744,
//       presetid: 17,
//       eventname: "NCIS",
//       version: 0,
//       starttime: 1670244600,
//       endtime: 1670248800,
//       shortinfo: null,
//       extendedinfo: "Le tueur de port en port frappe à nouveau, s'attaquant cette fois à Danny Price, l'ancien coéquipier de DiNozzo lorsqu'il était à la brigade criminelle de Baltimore. Ce dernier se remémore alors son passé, notamment sa première rencontre avec Gibbs.",
//       languageid: 27,
//       linktype: null,
//       targetindex: null,
//       genre: "GENRE_MOVIE",
//       scrambled: 0,
//       rating: null,
//       guidanceinfo: null,
//       videoformat: 2
//     };
//     const TVCall = "content://android.media.tv/channel/61"

//     const url = `${serverUrl}/#/joinevent?token=${token}&sessionId=${sessionId}&joinedurl=${Joinedurl}&programInfo=${tvProgramInfoCall}&channelId=${TVCall}`;
//     QRCode.toDataURL(url)
//       .then((url) => setQrCodeUrl(url))
//       .catch((err) => console.error("Error generating QR code", err));
//   }, []);
  
  
//   // console.log("rooms----------->1,",storedEmail)
//   useEffect(() => {
//     const img = new Image();
//     img.src = '../../public/images/view-3d-cinema-theatre-room.jpg';
//     img.onload = () => {
//       setIsImageLoaded(true);
//     };
//     const storedEmail = localStorage.getItem('userId');
//     const username = storedEmail.split("@")[0];
//     setUsernameid(username);
//   }, []);

//   function fetchAndStoreUserInfo() {
//     const macId = localStorage.getItem('macId');
//     // const macId = "8C-21-4A-9F-20-CC";
//     const localStorageKey = "userInfo";
//       axios
//         .get(`https://watchparty.tpvai.com/b/api/getuser/${macId}`)
//         .then((response) => {
//           const getuser = response.data.data;
//           localStorage.setItem(localStorageKey, JSON.stringify(getuser));
//           const storeUserInfo = JSON.parse(localStorage.getItem("userInfo"));
//           if (storeUserInfo) {
//             // Use `storeUserInfo.host_event` to populate the rooms
//             setRooms(storeUserInfo.host_event || []);
//             setJoinedRooms(storeUserInfo.joined || []);
//           } else {
//             console.error("No user info found in localStorage");
//           }
//         })
//         .catch((error) => {
//           console.error("Failed to fetch user info:", error);
//         });
  
//   }


//   useEffect(() => {
//     // Set the initial dateTime
//     setDateTime(formatDateTime());
//     fetchAndStoreUserInfo()
//     // Update the time every second
//     const interval = setInterval(() => {
//       setDateTime(formatDateTime());
//     }, 1000);

//     // Cleanup the interval on component unmount
//     return () => clearInterval(interval);
//   }, []);

//   const handleKeyDown = (event, index,roomId) => {
//     if (event.key === "ArrowRight") {
//       roomRefs.current[index + 1]?.focus();
//     } else if (event.key === "ArrowLeft") {
//       roomRefs.current[index - 1]?.focus();
//     } else if (event.key === "Enter") {
//       // alert(`Selected Room: Room ${index + 1}`);
//       navigate("/event/rooms",{ state: { roomId } });
//     }
//   };


//   const handleClick = () => {
//     navigate("/event/tvguid");
//   };

//   return (
//     // <div className="app">
//     <div className={`app ${isImageLoaded ? "high-quality" : "low-quality"}`}>
//       {/* Header Section */}
//       <header className="header">
//         <div className="logo-container">
//           <div className="logo">
//             <span>R</span>
//           </div>
          
//         </div>
//       </header>

//       {/* Sidebar */}
//       <div className="sidebar-watch">
//         <div className="sidebar-content-watch">
//           {/* My Rooms Section */}
//           <div className="sidebar-section-watch">
//             <h3>My Rooms</h3>
//             <button className="tv-guide-btn" onClick={handleClick}>
//               <span className="menu-icon">≡</span>
//               <span>TV Guide</span>
//             </button>
//             <p className="guide-text">
//               Add a room from TV Guide via Options or Programme info
//             </p>
//           </div>

//           {/* QR Code Section */}
//           <div className="qr-section">
//             <h3>Joined Rooms</h3>
//             {qrCodeUrl ? (
//               <img
//                 src={qrCodeUrl}
//                 alt="QR Code"
//                 style={{ width: "100px", height: "100px" }}
//               />
//             ) : (
//               <p>Loading QR Code...</p>
//             )}
//             <p className="guide-text">Scan to join a room from invitation</p>
//           </div>
//         </div>
//       </div>
//       {/* <div className="main-content-grid">
//         <div className="my-rooms">
          

// {rooms.map((room, index) => {
//   const showTime = room.showTime?.[0] || {};
//   const title = showTime.title || "806 First Dates Hotel";

//   // Convert start and end times to Date objects
//   const startTime = new Date(parseInt(showTime.start_time_utc_millis || "0"));
//   const endTime = new Date(parseInt(showTime.end_time_utc_millis || "0"));

//   // Format date and time
//   const formattedTimeRange = `${[
//     String(startTime.getDate()).padStart(2, "0"),
//     String(startTime.getMonth() + 1).padStart(2, "0"),
//     startTime.getFullYear(),
//   ].join("-")} ${startTime.toTimeString().slice(0, 5)} - ${endTime.toTimeString().slice(0, 5)}`;

//   // Format short date for display
//   const formattedShortDate = startTime.toLocaleDateString("en-GB", {
//     day: "2-digit",
//     month: "short",
//   });

//   return (
//     <div className="room-container" key={room.id || index}>
//       <div
//         className="room-card my-room-card"
//         tabIndex={0}
//         ref={(el) => (roomRefs.current[index] = el)}
//         onKeyDown={(event) => handleKeyDown(event, index, room.roomId)}
//       >
//         <p className="room-title">{title}</p>
//         <p className="room-time">{formattedTimeRange || "13-04-2022 15:30 - 16:45"}</p>
//       </div>
//       <p className="short-date">{formattedShortDate || "13 Apr"}</p>
//     </div>
//   );
// })}




          

          
//         </div>

//         <div className="joined-rooms">
           
// {rooms.map((room, index) => {
//   const showTime = room.showTime?.[0] || {};
//   const title = showTime.title || "806 First Dates Hotel";

//   // Convert start and end times to Date objects
//   const startTime = new Date(parseInt(showTime.start_time_utc_millis || "0"));
//   const endTime = new Date(parseInt(showTime.end_time_utc_millis || "0"));

//   // Format date and time
//   const formattedTimeRange = `${[
//     String(startTime.getDate()).padStart(2, "0"),
//     String(startTime.getMonth() + 1).padStart(2, "0"),
//     startTime.getFullYear(),
//   ].join("-")} ${startTime.toTimeString().slice(0, 5)} - ${endTime.toTimeString().slice(0, 5)}`;

//   // Format short date for display
//   const formattedShortDate = startTime.toLocaleDateString("en-GB", {
//     day: "2-digit",
//     month: "short",
//   });

//   const isExpired = room.isExpired; // Use room.isExpired from API response

//   return (
//     <div
//       className={`room-container ${isExpired ? "disabled-room" : ""}`}
//       key={room.id || index}
//     >
//       <div
//         className={`room-card my-room-card ${isExpired ? "disabled" : ""}`}
//         tabIndex={isExpired ? -1 : 0} // Prevent focus on expired rooms
//         ref={(el) => (roomRefs.current[index] = el)}
//         onKeyDown={(event) => !isExpired && handleKeyDown(event, index, room.roomId)}
//         style={{ pointerEvents: isExpired ? "none" : "auto", opacity: isExpired ? 0.5 : 1 }}
//       >
//         <p className="room-title">{title}</p>
//         <p className="room-time">{formattedTimeRange || "13-04-2022 15:30 - 16:45"}</p>
//       </div>
//       <p className="short-date">{formattedShortDate || "13 Apr"}</p>
//     </div>
//   );
// })}


//         </div>
//       </div> */}

// <div className="main-content-grid">
//   {/* {rooms.length === 0 ? ( */}
//   {rooms.length === 0 && JoinedRooms.length === 0 ? ( //temp should delete
//     <div className="welcome-container">
//   <div className="w-title">Welcome {usernameid}!</div>
//   <p className="welcome-message">
//     You may create a Watch room and invite your guests to join a watch session. Or simply join one.
//   </p>
//   <p className="welcome-requirements">
//     A Philips TV with Watch Room feature and Internet are required.
//   </p>
// </div>

//   ) : (
//     <>
//       <div className="my-rooms">
//         {/* {Array.from({ length: 3 }).map((_, index) => (
//           <div className="room-container" key={index}>
//             <div
//               className="room-card my-room-card"
//               tabIndex={0}
//               ref={(el) => (roomRefs.current[index] = el)}
//               onKeyDown={(event) => handleKeyDown(event, index)}
//             >
//               <p className="room-title">Room {index + 1}</p>
//               <p className="room-time">13-04-2022 15:30 - 16:45</p>
//             </div>
//             <p className="short-date">13 Apr</p>
//           </div>
//         ))} */}
//         {rooms.map((room, index) => {
//     const title = room.showTime?.title || "Default Room Title"; // Use the `tile` field for the title
//     const startTime = new Date(parseInt(room.showTime?.start || "0"));
//     const endTime = new Date(parseInt(room.showTime?.end || "0"));

//     const formattedTimeRange = `${[
//       String(startTime.getDate()).padStart(2, "0"),
//       String(startTime.getMonth() + 1).padStart(2, "0"),
//       startTime.getFullYear(),
//     ].join("-")} ${startTime.toTimeString().slice(0, 5)} - ${endTime.toTimeString().slice(0, 5)}`;

//     const formattedShortDate = startTime.toLocaleDateString("en-GB", {
//       day: "2-digit",
//       month: "short",
//     });

//     return (
//       <div className="room-container" key={room._id || index}>
//         <div
//           className="room-card my-room-card"
//           tabIndex={0}
//           ref={(el) => (roomRefs.current[index] = el)}
//           onKeyDown={(event) => handleKeyDown(event, index, room.roomId)}
//         >
//           <p className="room-title">{title}</p>
//           <p className="room-time">{formattedTimeRange}</p>
//         </div>
//         <p className="short-date">{formattedShortDate}</p>
//       </div>
//     );
//   })}
//       </div>
//       {/* <div className="joined-rooms">
//         {rooms.map((room, index) => {
//           const showTime = room.showTime?.[0] || {};
//           const title = showTime.title || "806 First Dates Hotel";

//           const startTime = new Date(parseInt(showTime.start_time_utc_millis || "0"));
//           const endTime = new Date(parseInt(showTime.end_time_utc_millis || "0"));

//           const formattedTimeRange = `${[
//             String(startTime.getDate()).padStart(2, "0"),
//             String(startTime.getMonth() + 1).padStart(2, "0"),
//             startTime.getFullYear(),
//           ].join("-")} ${startTime.toTimeString().slice(0, 5)} - ${endTime.toTimeString().slice(0, 5)}`;

//           const formattedShortDate = startTime.toLocaleDateString("en-GB", {
//             day: "2-digit",
//             month: "short",
//           });

//           const isExpired = room.isExpired;

//           return (
//             <div
//               className={`room-container ${isExpired ? "disabled-room" : ""}`}
//               key={room.id || index}
//             >
//               <div
//                 className={`room-card my-room-card ${isExpired ? "disabled" : ""}`}
//                 tabIndex={isExpired ? -1 : 0}
//                 ref={(el) => (roomRefs.current[index] = el)}
//                 onKeyDown={(event) =>
//                   !isExpired && handleKeyDown(event, index, room.roomId)
//                 }
//                 style={{
//                   pointerEvents: isExpired ? "none" : "auto",
//                   opacity: isExpired ? 0.5 : 1,
//                 }}
//               >
//                 <p className="room-title">{title}</p>
//                 <p className="room-time">{formattedTimeRange || "13-04-2022 15:30 - 16:45"}</p>
//               </div>
//               <p className="short-date">{formattedShortDate || "13 Apr"}</p>
//             </div>
//           );
//         })}
//       </div> */}
//       <div className="joined-rooms">
//   {JoinedRooms.map((room, index) => {
//     const title = room.showTime?.title || "Default Room Title"; // Use the `tile` field for the title
//     const startTime = new Date(parseInt(room.showTime?.start || "0"));
//     const endTime = new Date(parseInt(room.showTime?.end || "0"));

//     const formattedTimeRange = `${[
//       String(startTime.getDate()).padStart(2, "0"),
//       String(startTime.getMonth() + 1).padStart(2, "0"),
//       startTime.getFullYear(),
//     ].join("-")} ${startTime.toTimeString().slice(0, 5)} - ${endTime.toTimeString().slice(0, 5)}`;

//     const formattedShortDate = startTime.toLocaleDateString("en-GB", {
//       day: "2-digit",
//       month: "short",
//     });

//     return (
//       <div className="room-container" key={room._id || index}>
//         <div
//           className="room-card my-room-card"
//           tabIndex={0}
//           ref={(el) => (roomRefs.current[index] = el)}
//           onKeyDown={(event) => handleKeyDown(event, index, room.roomId)}
//         >
//           <p className="room-title">{title}</p>
//           <p className="room-time">{formattedTimeRange}</p>
//         </div>
//         <p className="short-date">{formattedShortDate}</p>
//       </div>
//     );
//   })}
// </div>

//     </>
//   )}
// </div>

//       <div className="footer">
//         <span ></span>
//         <button style={{
//           color: '#b3b3b3',
//           border: 'none',
//           background: 'none',
//           cursor: 'pointer',
//           display: 'flex',
//           alignItems: 'center',
//           gap: '0.5rem',
//           fontSize:'smaller'
//         }}>
//          {dateTime} <button class="plus-button"></button>
//          OPTIONS
//         </button>
//       </div>
//     </div>
//   );
// };

// export default WatchRoom;



//update code 3-2-25
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { CirclePlus } from 'lucide-react';
import QRCode from "qrcode";
import "./WatchRoom.css";


// +1
const generateRandomString = (length) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }
  return result;
};

const WatchRoom = ({socket}) => {
  const [qrCodeUrl, setQrCodeUrl] = useState("");
  const [rooms, setRooms] = useState([]);
  const [JoinedRooms, setJoinedRooms] = useState([]);
  const [usernameid, setUsernameid] = useState([]);
  const [isImageLoaded, setIsImageLoaded] = useState(false); // Manage background image state
  const [dateTime, setDateTime] = useState("");

  // +2
  const [eventData, setEventdata] = useState(null)
  const [showMessage, setShowMessage] = useState(false);
  const [showtempdata, setShowtempdata] = useState("");
  const [qrCodeImageUrl, setQrCodeImageUrl] = useState();
  const [tvConnectionData, setTVConnectionData] = useState(null);

  const roomRefs = useRef([]);

  const navigate = useNavigate()
  // useEffect(() => {
  //   // Generate QR Code
  //   QRCode.toDataURL("https://example.com")
  //     .then((url) => setQrCodeUrl(url))
  //     .catch((err) => console.error("Error generating QR code", err));
  // }, []);

  const formatDateTime = () => {
    const now = new Date();
    const hours = now.getHours().toString().padStart(2, "0");
    const minutes = now.getMinutes().toString().padStart(2, "0");
    const day = now.toLocaleString("en-US", { weekday: "short" });
    const date = now.getDate().toString().padStart(2, "0");
    const month = now.toLocaleString("en-US", { month: "short" });
    const year = now.getFullYear();

    return `${hours}:${minutes} ${day} ${date} ${month} ${year}`;
  };


// +3
  // const serverUrl = "http://localhost:3000";
  const serverUrl = process.env.REACT_APP_SOCKET_URL;
  const conv = "/" + generateRandomString(6);
  const Joinedurl = serverUrl + conv;


  // +4
  useEffect(() => {
    console.log("useEffect running...");
    const generateQRCode = (token, sessionId) => {
      const additionalData = {
        macId: "8C-21-4A-9F-20-C1",
        room_id: {
          roomId: "93a2423f-b06e-43ca-8e76-244fa4e5a819",
          isExpired: false,
          showTime: {
            title: "Mirette ermittelt1",
            start: "1670322000000",
            end: "1670322600000"
          }
        },
        type: "joined"
      };

      const url = `${serverUrl}/#/joinevent?token=${token}&sessionId=${sessionId}&joinedurl=${Joinedurl}&data=${encodeURIComponent(JSON.stringify(additionalData))}`;
      setShowtempdata(url);
      console.log("url", url);

      // const urltemp = `${serverUrl}/#/joinevent?token=${token}&sessionId=${sessionId}&joinedurl=${Joinedurl}`;
      QRCode.toDataURL(url, (err, qrCodeUrl) => {
        if (err) {
          console.error('Error generating QR code:', err);
          return;
        }
        setQrCodeImageUrl(qrCodeUrl);
        console.log("qrcodeurlllllllll", qrCodeUrl);
        socket.emit('qrCodeScanned', { data: url });
      });
    };

    const generateTokenAndSessionId = () => {
      const token = generateRandomString(10);
      const sessionId = generateRandomString(10);
      return { token, sessionId };
    };

    const { token, sessionId } = generateTokenAndSessionId();
    console.log('token', token);
    console.log('session', sessionId);
    generateQRCode(token, sessionId);

    const urlParams = new URLSearchParams(window.location.search);
    const scannedToken = urlParams.get('token');
    const scannedSessionId = urlParams.get('sessionId');

    if (scannedToken && scannedSessionId) {
      navigate('/joinscan');
    }
  }, [navigate, socket]);


  // +5
  // useEffect(() => {
  //   socket.on("TVconnected", function (response) {
  //     console.log("Socket response:", response);
  //     // Store the response data in the state with the proper format
  //     setTVConnectionData(response.data);
  //     // setEventdata(JSON.stringify(response.data.additionalData, null, 2))
  //     const payloadapi = JSON.stringify(response.data.additionalData, null, 2)

  //     setShowMessage(true);
  //   });
  // }, [socket]);



  // useEffect(() => {
  //   console.log("rrrrrrrrrrrrrr")
  //   socket.on("TVconnected", function (response) {
  //     console.log("Socket response:", response);
  //     setEventdata(JSON.stringify(response.data.additionalData, null, 2))
  //   });
  // }, [socket]);

  // api trigger
  useEffect(() => {
    socket.on("TVconnected", async (response) => {
      console.log("Socket response:", response);
      setTVConnectionData(response.data);
      const payload = response.data.additionalData; // Assuming the correct payload structure

      if (payload) {
        try {
          const res = await axios.post("https://watchparty.tpvai.com/b/api/updateuser", payload, {
            headers: {
              "Content-Type": "application/json",
            },
          });
          fetchAndStoreUserInfo()
          
        } catch (error) {
          console.error("Error updating user:", error);
        }
      }
      setShowMessage(true);
    });
    return () => {
      socket.off("TVconnected"); // Cleanup to avoid multiple listeners
    };
  }, [socket]);


  // useEffect(() => {
  // const storeUserInfo = JSON.parse(localStorage.getItem("userInfo"));
  //   // Generate QR Code
  //   QRCode.toDataURL("https://example.com")
  //     .then((url) => setQrCodeUrl(url))
  //     .catch((err) => console.error("Error generating QR code", err));

  //   // Fetch watch rooms data
  //   fetch("https://watchparty.tpvai.com/b/api/getWatchRooms")
  //     .then((response) => response.json())
  //     .then((data) => {
  //       // setRooms(data); 
  //       setRooms(storeUserInfo)

  //     })
  //     .catch((error) => console.error("Error fetching rooms:", error));
  // }, []);
  // console.log("rooms----------->",rooms)
  
  
  //new---1111111
  // useEffect(() => { 
  //   // Retrieve stored user information from localStorage
  
  
  //   // Generate QR Code (if needed)
  //   const serverUrl = process.env.REACT_APP_WEB_URL;
  //   const generateRandomString = (length) => {
  //     const characters =
  //       "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  //     let result = "";
  //     for (let i = 0; i < length; i++) {
  //       const randomIndex = Math.floor(Math.random() * characters.length);
  //       result += characters.charAt(randomIndex);
  //     }
  //     return result;
  //   };
  //   const generateTokenAndSessionId = () => {
  //     const token = generateRandomString(10);
  //     const sessionId = generateRandomString(10);
  //     return { token, sessionId };
  //   };

  //   const { token, sessionId } = generateTokenAndSessionId();
  //   const Joinedurl = "https://wp.tpvai.com"
  //   const tvProgramInfoCall = {
  //     _id: 5761,
  //     eventid: 6744,
  //     presetid: 17,
  //     eventname: "NCIS",
  //     version: 0,
  //     starttime: 1670244600,
  //     endtime: 1670248800,
  //     shortinfo: null,
  //     extendedinfo: "Le tueur de port en port frappe à nouveau, s'attaquant cette fois à Danny Price, l'ancien coéquipier de DiNozzo lorsqu'il était à la brigade criminelle de Baltimore. Ce dernier se remémore alors son passé, notamment sa première rencontre avec Gibbs.",
  //     languageid: 27,
  //     linktype: null,
  //     targetindex: null,
  //     genre: "GENRE_MOVIE",
  //     scrambled: 0,
  //     rating: null,
  //     guidanceinfo: null,
  //     videoformat: 2
  //   };
  //   const TVCall = "content://android.media.tv/channel/61"

  //   const url = `${serverUrl}/#/joinevent?token=${token}&sessionId=${sessionId}&joinedurl=${Joinedurl}&programInfo=${tvProgramInfoCall}&channelId=${TVCall}`;
  //   QRCode.toDataURL(url)
  //     .then((url) => setQrCodeUrl(url))
  //     .catch((err) => console.error("Error generating QR code", err));
  // }, []);
  
  
  // console.log("rooms----------->1,",storedEmail)
  useEffect(() => {
    const img = new Image();
    img.src = '../../public/images/view-3d-cinema-theatre-room.jpg';
    img.onload = () => {
      setIsImageLoaded(true);
    };
    const storedEmail = localStorage.getItem('userId');
    const username = storedEmail.split("@")[0];
    setUsernameid(username);
  }, []);

  function fetchAndStoreUserInfo() {
    // const macId = localStorage.getItem('macId');
    const macId = "8C-21-4A-9F-20-C1";
    const localStorageKey = "userInfo";
      axios
        .get(`https://watchparty.tpvai.com/b/api/getuser/${macId}`)
        .then((response) => {
          const getuser = response.data.data;
          localStorage.setItem(localStorageKey, JSON.stringify(getuser));
          const storeUserInfo = JSON.parse(localStorage.getItem("userInfo"));
          if (storeUserInfo) {
            // Use `storeUserInfo.host_event` to populate the rooms
            setRooms(storeUserInfo.host_event || []);
            setJoinedRooms(storeUserInfo.joined || []);
          } else {
            console.error("No user info found in localStorage");
          }
        })
        .catch((error) => {
          console.error("Failed to fetch user info:", error);
        });
  
  }


  useEffect(() => {
    // Set the initial dateTime
    setDateTime(formatDateTime());
    fetchAndStoreUserInfo()
    // Update the time every second
    const interval = setInterval(() => {
      setDateTime(formatDateTime());
    }, 1000);

    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, []);

  const handleKeyDown = (event, index,roomId) => {
    if (event.key === "ArrowRight") {
      roomRefs.current[index + 1]?.focus();
    } else if (event.key === "ArrowLeft") {
      roomRefs.current[index - 1]?.focus();
    } else if (event.key === "Enter") {
      // alert(`Selected Room: Room ${index + 1}`);
      navigate("/event/rooms",{ state: { roomId } });
    }
  };


  const handleClick = () => {
    navigate("/event/tvguid");
  };

  return (
    // <div className="app">
    <div className={`app ${isImageLoaded ? "high-quality" : "low-quality"}`}>
      {/* Header Section */}
      <header className="header">
        <div className="logo-container">
          <div className="logo">
            <span>R</span>
          </div>
          
        </div>
      </header>

      {/* Sidebar */}
      <div className="sidebar-watch">
        <div className="sidebar-content-watch">
          {/* My Rooms Section */}
          <div className="sidebar-section-watch">
            <h3>My Rooms</h3>
            <button className="tv-guide-btn" onClick={handleClick}>
              <span className="menu-icon">≡</span>
              <span>TV Guide</span>
            </button>
            <p className="guide-text">
              Add a room from TV Guide via Options or Programme info
            </p>
          </div>

          {/* QR Code Section */}
          <div className="qr-section">
            <h3>Joined Rooms</h3>
            {/* <img src={qrCodeImageUrl} alt="qrcode" /> */}
            {qrCodeImageUrl ? (
              <img
                src={qrCodeImageUrl}
                alt="QR Code"
                style={{ width: "100px", height: "100px" }}
              />
            ) : (
              <p>Loading QR Code...</p>
            )}
            {/* <p>{showtempdata}</p> */}
            <pre>Convert Data:---{eventData}</pre>
            {/* <p className="guide-text">Scan to join a room from invitation</p> */}
          </div>
        </div>
      </div>
      {/* <div className="main-content-grid">
        <div className="my-rooms">
          

{rooms.map((room, index) => {
  const showTime = room.showTime?.[0] || {};
  const title = showTime.title || "806 First Dates Hotel";

  // Convert start and end times to Date objects
  const startTime = new Date(parseInt(showTime.start_time_utc_millis || "0"));
  const endTime = new Date(parseInt(showTime.end_time_utc_millis || "0"));

  // Format date and time
  const formattedTimeRange = `${[
    String(startTime.getDate()).padStart(2, "0"),
    String(startTime.getMonth() + 1).padStart(2, "0"),
    startTime.getFullYear(),
  ].join("-")} ${startTime.toTimeString().slice(0, 5)} - ${endTime.toTimeString().slice(0, 5)}`;

  // Format short date for display
  const formattedShortDate = startTime.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "short",
  });

  return (
    <div className="room-container" key={room.id || index}>
      <div
        className="room-card my-room-card"
        tabIndex={0}
        ref={(el) => (roomRefs.current[index] = el)}
        onKeyDown={(event) => handleKeyDown(event, index, room.roomId)}
      >
        <p className="room-title">{title}</p>
        <p className="room-time">{formattedTimeRange || "13-04-2022 15:30 - 16:45"}</p>
      </div>
      <p className="short-date">{formattedShortDate || "13 Apr"}</p>
    </div>
  );
})}




          

          
        </div>

        <div className="joined-rooms">
           
{rooms.map((room, index) => {
  const showTime = room.showTime?.[0] || {};
  const title = showTime.title || "806 First Dates Hotel";

  // Convert start and end times to Date objects
  const startTime = new Date(parseInt(showTime.start_time_utc_millis || "0"));
  const endTime = new Date(parseInt(showTime.end_time_utc_millis || "0"));

  // Format date and time
  const formattedTimeRange = `${[
    String(startTime.getDate()).padStart(2, "0"),
    String(startTime.getMonth() + 1).padStart(2, "0"),
    startTime.getFullYear(),
  ].join("-")} ${startTime.toTimeString().slice(0, 5)} - ${endTime.toTimeString().slice(0, 5)}`;

  // Format short date for display
  const formattedShortDate = startTime.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "short",
  });

  const isExpired = room.isExpired; // Use room.isExpired from API response

  return (
    <div
      className={`room-container ${isExpired ? "disabled-room" : ""}`}
      key={room.id || index}
    >
      <div
        className={`room-card my-room-card ${isExpired ? "disabled" : ""}`}
        tabIndex={isExpired ? -1 : 0} // Prevent focus on expired rooms
        ref={(el) => (roomRefs.current[index] = el)}
        onKeyDown={(event) => !isExpired && handleKeyDown(event, index, room.roomId)}
        style={{ pointerEvents: isExpired ? "none" : "auto", opacity: isExpired ? 0.5 : 1 }}
      >
        <p className="room-title">{title}</p>
        <p className="room-time">{formattedTimeRange || "13-04-2022 15:30 - 16:45"}</p>
      </div>
      <p className="short-date">{formattedShortDate || "13 Apr"}</p>
    </div>
  );
})}


        </div>
      </div> */}

<div className="main-content-grid">
  {/* {rooms.length === 0 ? ( */}
  {rooms.length === 0 && JoinedRooms.length === 0 ? ( //temp should delete
    <div className="welcome-container">
  <div className="w-title">Welcome {usernameid}!</div>
  <p className="welcome-message">
    You may create a Watch room and invite your guests to join a watch session. Or simply join one.
  </p>
  <p className="welcome-requirements">
    A Philips TV with Watch Room feature and Internet are required.
  </p>
</div>

  ) : (
    <>
      <div className="my-rooms">
        {/* {Array.from({ length: 3 }).map((_, index) => (
          <div className="room-container" key={index}>
            <div
              className="room-card my-room-card"
              tabIndex={0}
              ref={(el) => (roomRefs.current[index] = el)}
              onKeyDown={(event) => handleKeyDown(event, index)}
            >
              <p className="room-title">Room {index + 1}</p>
              <p className="room-time">13-04-2022 15:30 - 16:45</p>
            </div>
            <p className="short-date">13 Apr</p>
          </div>
        ))} */}
        {rooms.map((room, index) => {
    const title = room.showTime?.title || "Default Room Title"; // Use the `tile` field for the title
    const startTime = new Date(parseInt(room.showTime?.start || "0"));
    const endTime = new Date(parseInt(room.showTime?.end || "0"));

    const formattedTimeRange = `${[
      String(startTime.getDate()).padStart(2, "0"),
      String(startTime.getMonth() + 1).padStart(2, "0"),
      startTime.getFullYear(),
    ].join("-")} ${startTime.toTimeString().slice(0, 5)} - ${endTime.toTimeString().slice(0, 5)}`;

    const formattedShortDate = startTime.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
    });

    return (
      <div className="room-container" key={room._id || index}>
        <div
          className="room-card my-room-card"
          tabIndex={0}
          ref={(el) => (roomRefs.current[index] = el)}
          onKeyDown={(event) => handleKeyDown(event, index, room.roomId)}
        >
          <p className="room-title">{title}</p>
          <p className="room-time">{formattedTimeRange}</p>
        </div>
        <p className="short-date">{formattedShortDate}</p>
      </div>
    );
  })}
      </div>
      {/* <div className="joined-rooms">
        {rooms.map((room, index) => {
          const showTime = room.showTime?.[0] || {};
          const title = showTime.title || "806 First Dates Hotel";

          const startTime = new Date(parseInt(showTime.start_time_utc_millis || "0"));
          const endTime = new Date(parseInt(showTime.end_time_utc_millis || "0"));

          const formattedTimeRange = `${[
            String(startTime.getDate()).padStart(2, "0"),
            String(startTime.getMonth() + 1).padStart(2, "0"),
            startTime.getFullYear(),
          ].join("-")} ${startTime.toTimeString().slice(0, 5)} - ${endTime.toTimeString().slice(0, 5)}`;

          const formattedShortDate = startTime.toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "short",
          });

          const isExpired = room.isExpired;

          return (
            <div
              className={`room-container ${isExpired ? "disabled-room" : ""}`}
              key={room.id || index}
            >
              <div
                className={`room-card my-room-card ${isExpired ? "disabled" : ""}`}
                tabIndex={isExpired ? -1 : 0}
                ref={(el) => (roomRefs.current[index] = el)}
                onKeyDown={(event) =>
                  !isExpired && handleKeyDown(event, index, room.roomId)
                }
                style={{
                  pointerEvents: isExpired ? "none" : "auto",
                  opacity: isExpired ? 0.5 : 1,
                }}
              >
                <p className="room-title">{title}</p>
                <p className="room-time">{formattedTimeRange || "13-04-2022 15:30 - 16:45"}</p>
              </div>
              <p className="short-date">{formattedShortDate || "13 Apr"}</p>
            </div>
          );
        })}
      </div> */}
      <div className="joined-rooms">
  {JoinedRooms.map((room, index) => {
    const title = room.showTime?.title || "Default Room Title"; // Use the `tile` field for the title
    const startTime = new Date(parseInt(room.showTime?.start || "0"));
    const endTime = new Date(parseInt(room.showTime?.end || "0"));

    const formattedTimeRange = `${[
      String(startTime.getDate()).padStart(2, "0"),
      String(startTime.getMonth() + 1).padStart(2, "0"),
      startTime.getFullYear(),
    ].join("-")} ${startTime.toTimeString().slice(0, 5)} - ${endTime.toTimeString().slice(0, 5)}`;

    const formattedShortDate = startTime.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
    });

    return (
      <div className="room-container" key={room._id || index}>
        <div
          className="room-card my-room-card"
          tabIndex={0}
          ref={(el) => (roomRefs.current[index] = el)}
          onKeyDown={(event) => handleKeyDown(event, index, room.roomId)}
        >
          <p className="room-title">{title}</p>
          <p className="room-time">{formattedTimeRange}</p>
        </div>
        <p className="short-date">{formattedShortDate}</p>
      </div>
    );
  })}
</div>

    </>
  )}
</div>

      <div className="footer">
        <span ></span>
        <button style={{
          color: '#b3b3b3',
          border: 'none',
          background: 'none',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          gap: '0.5rem',
          fontSize:'smaller'
        }}>
         {dateTime} <button class="plus-button"></button>
         OPTIONS
        </button>
      </div>
    </div>
  );
};

export default WatchRoom;