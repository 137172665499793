// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';

// function EnterEmail() {
//   const [name, setName] = useState('');
//   const navigate = useNavigate();

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     if (name.trim()) {
//       localStorage.setItem('userName', name); // Save name in localStorage
//       navigate('/main'); // Redirect to the main page
//     }
//   };

//   return (
//     <div>
//       {/* <h1>Enter Your Name</h1> */}
//       <form onSubmit={handleSubmit}>
//         <input
//           type="text"
//           placeholder="Enter your name"
//           value={name}
//           onChange={(e) => setName(e.target.value)}
//         />
//         <button type="submit">Submit</button>
//       </form>
//     </div>
//   );
// }

// export default EnterEmail;


// import React, { useEffect, useRef, useState } from "react";
// import { useNavigate } from "react-router-dom";
// import { useTranslation } from '../utils/TranslationContext';

// function EnterEmail() {
//   const history = useNavigate();

//   const inputRef = useRef(null);
//   const [outputText, setOutputText] = useState("");
//   const [emailId, setEmailId] = useState("Guest");
//   const [AddemailId, setAddEmailId] = useState("");
//   const loginButtonRef = useRef(null);


//   const { t } = useTranslation();


//   useEffect(() => {
//     inputRef.current.focus();

//     const storedEmail = localStorage.getItem("userId");
//     if (storedEmail) {
//       const username = storedEmail.split("@")[0];
//       setEmailId(username);
//       setAddEmailId(username);
//     }
//   }, []);

//   const handleKeyDown = (event) => {
//     if (event.keyCode === 13) {
//       inputRef.current.focus();
//       saveEmailToLocalStorage();
//     }
//   };

//   const handleChange = (event) => {
//     const email = event.target.value;
//     setEmailId(email);
//   };

//   const handleKeyPress = (event) => {
//     if (event.keyCode === 13) {
//       const enteredText = event.target.value;
//       setOutputText(enteredText);
//       setEmailId(enteredText);
//       event.target.value = "";
//     } else if (event.key === "Enter") {
//       loginButtonRef.current.click();
//       console.log("Hit Enter");
//     }
//   };

//   const saveEmailToLocalStorage = () => {
//     console.log("emailId", emailId);
//     // let HashEmailid = emailId + "@" + "tv.com";
//     let HashEmailid = emailId + "@" + Math.floor(10000 + Math.random() * 90000);
//     localStorage.setItem("userId", HashEmailid);
//     history("/main");
//   };

//   return (
//     <div className="container" onKeyDown={handleKeyDown}>
//       <div className="row min-vh-100 justify-content-center">
//         <div className="col-12 text-center"></div>
//         <div
//           className="col-md-8 col-lg-6 col-xl-4 text-center"
//           style={{ marginTop: "-5%", marginBottom: "3%" }}
//         >
//           {/* <h5>Welcome to Watchparty</h5> */}
//           <div className="card">
//             {AddemailId ? (
//               <div>
//                 {t('Hi')} <span style={{ fontWeight: "bold" }}>{emailId}</span>,
//                 {t('Welcome to WatchRoom')}.
//               </div>
//             ) : (
//               <div style={{ fontSize: "15px" }}>
//                 {t('Please input your name to chat with me.')}
//               </div>
//             )}
//             <div className="card-body text-center">
//               <input
//                 ref={inputRef}
//                 type="text"
//                 placeholder="Enter Your Name"
//                 style={{
//                   width: "100%",
//                   padding: "8px",
//                   boxSizing: "border-box",
//                   outline: "none",
//                   border: "none",
//                 }}
//                 onKeyPress={handleKeyPress}
//                 onChange={handleChange}
//                 value={emailId}
//               />
//               {/* <button ref={loginButtonRef} class="button-24" onClick={saveEmailToLocalStorage}>Save</button> */}
//               {AddemailId ? (
//                 <button className="button-24" onClick={saveEmailToLocalStorage}>
//                   {t('Update')}
//                 </button>
//               ) : (
//                 <button className="button-24" onClick={saveEmailToLocalStorage}>
//                   {t('Save')}
//                 </button>
//               )}
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default EnterEmail;



// api add 
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "../utils/TranslationContext";
import axios from "axios";

function EnterEmail() {
  const history = useNavigate();

  const inputRef = useRef(null);
  const [emailId, setEmailId] = useState("Guest");
  const [AddemailId, setAddEmailId] = useState("");
  const [macAddress, setMacAddress] = useState("");
  const [errorMessage, setErrorMessage] = useState(""); // State for showing error messages
  const { t } = useTranslation();


  useEffect(() => {
    // const mac = "8C-21-4A-9F-20-C1"
    // localStorage.setItem("macId", mac);
    // setMacAddress(mac)
    if (window.JavaFun && typeof window.JavaFun.getMacAddressOfTV === "function") {
      // const mac = window.JavaFun.getMacAddressOfTV(); 
      const mac = "8C-21-4A-9F-20-C1"
      localStorage.setItem("macId", mac);
      setMacAddress(mac)
    } else {
      console.warn("JavaFun or getMacAddressOfTV is not available.");
    }
  }, []);
  
  useEffect(() => {
    inputRef.current.focus();

    const storedEmail = localStorage.getItem("userId");
    if (storedEmail) {
      const username = storedEmail.split("@")[0];
      setEmailId(username);
      setAddEmailId(username);
    }
  }, []);

  const handleChange = (event) => {
    setEmailId(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      inputRef.current.focus();
      saveEmailToLocalStorageAndApi();
      console.log("Hit Enter");
    }
  };
  

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      saveEmailToLocalStorageAndApi();
      console.log("Hit Enter");
    }
  };
  

  const saveEmailToLocalStorageAndApi = async () => {
    if (!emailId.trim()) {
      setErrorMessage("Please enter a valid name.");
      return;
    }

    // Check for network connectivity
    if (!navigator.onLine) {
      setErrorMessage("No internet connection. Please check your network and try again.");
      return;
    }

    try {
      setErrorMessage(""); // Clear any previous errors

      // Generate the payload
      const payload = {
        macId: macAddress, 
        username: emailId,
      };

      // Make the API call with axios
      const response = await axios.post(
        "https://watchparty.tpvai.com/b/api/createuser",
        payload
      );

      console.log("API Response:", response.data.data);

      // Save userId to localStorage
      const HashEmailid = emailId + "@" + Math.floor(10000 + Math.random() * 90000);
      localStorage.setItem("userId", HashEmailid);

      // Save API response to localStorage as userInfo
      // localStorage.setItem("userInfo", JSON.stringify(response.data.data));

      // Redirect to /main
      history("/main");
    } catch (error) {
      console.error("Error while creating user:", error);

      // Display error message to the user
      if (error.response) {
        // Server returned a response
        setErrorMessage(`Error: ${error.response.data.message || "Something went wrong."}`);
      } else if (error.request) {
        // No response from server
        setErrorMessage("Error: No response from the server. Please try again later.");
      } else {
        // Any other error
        setErrorMessage("Error: Failed to create user. Please try again.");
      }
    }
  };

  return (
    <div className="container" onKeyDown={handleKeyDown}>
      <div className="row min-vh-100 justify-content-center">
        <div className="col-12 text-center"></div>
        <div
          className="col-md-8 col-lg-6 col-xl-4 text-center"
          style={{ marginTop: "-5%", marginBottom: "3%" }}
        >
          <div className="card">
            {AddemailId ? (
              <div>
                {t("Hi")} <span style={{ fontWeight: "bold" }}>{emailId}</span>, {t("Welcome to WatchRoom")}.
              </div>
            ) : (
              
              <div style={{ fontSize: "15px" }}>{t("Please input your name to chat with me.")}
              </div>
              
            )}
            <div className="card-body text-center">
            Your mac:- {macAddress}
            <input
              ref={inputRef}
              type="text"
              placeholder="Enter Your Name"
              style={{
                width: "100%",
                padding: "8px",
                boxSizing: "border-box",
                outline: "none",
                border: "none",
              }}
              onKeyPress={handleKeyPress}
              onChange={handleChange}
              value={emailId}
            />

              <button className="button-24" onClick={saveEmailToLocalStorageAndApi}>
                {AddemailId ? t("Update") : t("Save")}
              </button>
              {errorMessage && (
                <div style={{ color: "red", marginTop: "10px" }}>
                  {errorMessage}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EnterEmail;
