// import React from 'react';
// import { HashRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
// import EnterEmail from './components/EnterEmail';
// import Main from './components/Main';
// import Welcome from './components/Welcome';
// import Event from './components/Event';
// import Tvguid from './components/Tvguid';
// import ChannelInfo from './components/ChannelInfo';
// import Rooms from './components/Rooms';
// import MainWatchRoom from './components/watchroomwindow/MainWatchRoom';

// function App() {
//   const userName = localStorage.getItem('userName'); // Check if the user name exists

//   return (
//     <Router>
//       <Routes>
//         {/* If the user name exists, redirect to /main; otherwise, show EnterEmail */}
//         <Route
//           path="/"
//           element={userName ? <Navigate to="/main" /> : <EnterEmail />}
//         />

//         {/* Main Route */}
//         <Route path="/main" element={<Main />} />
//         {/* <Route path="/main" element={<ChannelInfo />} /> */}

        
//         {/* Current Route */}
//         <Route path="/welcome" element={<Welcome />} />
        
//         {/* Upcoming Routes */}
//         <Route path="/event" element={<Event />} />
//         <Route path="/event/tvguid" element={<Tvguid />}>
//           <Route path="channelinfo" element={<ChannelInfo />} />
//         </Route>
//         <Route path="/event/rooms" element={<MainWatchRoom />} />
//         <Route path="/event/info" element={<ChannelInfo />} />  


//         {/* Fallback Route */}
//         <Route path="*" element={<Navigate to="/" />} />
//       </Routes>
//     </Router>
//   );
// }

// export default App;




// import React, { useState } from "react";
// import { HashRouter as Router, Routes, Route, Navigate } from "react-router-dom";
// import { io } from "socket.io-client";
// import EnterEmail from "./components/EnterEmail";
// import Main from "./components/Main";
// import Welcome from "./components/Welcome";
// import Event from "./components/Event";
// import Tvguid from "./components/Tvguid";
// import ChannelInfo from "./components/ChannelInfo";
// import Rooms from "./components/Rooms";
// import MainWatchRoom from "./components/watchroomwindow/MainWatchRoom";
// import JoinScanner from "./components/joinscanner";
// import Qrcodezone from "./components/Qrcodezone";
// import WelcomeScreen from "./components/WelcomeScreen";
// import NotFound from "./components/NotFound";
// import ChatPage from "./components/ChatPage";
// import { TranslationProvider } from "./utils/TranslationContext";
// import "./App.css";


//working code
// import React, { useEffect, useState } from 'react';
// import { HashRouter as Router, Routes, Route, Navigate } from "react-router-dom";
// import { io } from 'socket.io-client';
// import EnterEmail from './components/EnterEmail';
// import Main from './components/Main';
// import Welcome from './components/Welcome';
// import Event from './components/Event';
// import Tvguid from './components/Tvguid';
// import ChannelInfo from './components/ChannelInfo';
// import Rooms from './components/Rooms';
// import MainWatchRoom from './components/watchroomwindow/MainWatchRoom';

// import './App.css';
// import JoinScanner from './components_old/joinscanner';
// import Qrcodezone from './components_old/Qrcodezone';
// import WelcomeScreen from './components_old/WelcomeScreen';
// import NotFound from './components_old/NotFound';
// import ChatPage from './components_old/ChatPage';
// import { TranslationProvider } from './utils/TranslationContext';
// import LanguageSwitcher from './utils/LanguageSwitcher';


// // Initialize Socket.io connection
// const socket = io(process.env.REACT_APP_SOCKET_URL);

// console.log("Socket Connection Established:", socket);

// const weburl = process.env.REACT_APP_WEB_URL;
// const apiurl = process.env.REACT_APP_API_URL;
// const version = process.env.REACT_APP_VERSION;

// console.log("Web URL:", weburl);
// console.log("API URL:", apiurl);
// console.log("Version:", version);

// function App() {
//   const [username, setUsername] = useState(localStorage.getItem("userId") || "");
//   const [isLoggedIn, setIsLoggedIn] = useState(!!username);

//   const handleLogin = (username) => {
//     setIsLoggedIn(true);
//     setUsername(username);
//     let HashEmailid = username + "@" + Math.floor(10000 + Math.random() * 90000);
//     localStorage.setItem("userId", HashEmailid); // Store username in localStorage
//   };

//   return (
//     <TranslationProvider>
//       <Router>
//         <Routes>
//           {/* Default Route: Redirect based on login status */}
//           <Route
//             path="/"
//             element={isLoggedIn ? <Navigate to="/main" /> : <EnterEmail onLogin={handleLogin} />}
//           />

//           {/* Main Routes */}
//           <Route path="/main" element={<Main />} />
//           <Route path="/welcome" element={<Welcome />} />
//           <Route path="/event" element={<Event />} />
//           <Route path="/event/tvguid" element={<Tvguid />} />
//           <Route path="/event/tvguid/channelinfo" element={<ChannelInfo />} />
//           <Route path="/event/rooms" element={<MainWatchRoom />} />
//           <Route path="/event/info" element={<ChannelInfo />} />

//           {/* Additional Routes with Socket Integration */}
//           <Route path="/chat" element={<ChatPage username={username} socket={socket} />} />
//           <Route path="/letchat" element={<WelcomeScreen onLogin={handleLogin} socket={socket} />} />
//           <Route path="/joinscan" element={<JoinScanner socket={socket} />} />
//           <Route path="/current/welcome" element={<Qrcodezone socket={socket} />} />

//           {/* Fallback Route */}
//           <Route path="*" element={<NotFound />} />
//         </Routes>
//       </Router>
//     </TranslationProvider>
//   );
// }

// export default App;


//url as joined 21-01-25
import React, { useEffect, useState } from 'react';
import { HashRouter as Router, Routes, Route, Navigate ,useNavigate ,  useParams } from "react-router-dom";
import { io } from 'socket.io-client';
import EnterEmail from './components/EnterEmail';
import Main from './components/Main';
import Welcome from './components/Welcome';
import Event from './components/Event';
import Tvguid from './components/Tvguid';
import ChannelInfo from './components/ChannelInfo';
import Rooms from './components/Rooms';
import MainWatchRoom from './components/watchroomwindow/MainWatchRoom';
import ProtectedRoute from "./utils/ProtectedRoute";

import './App.css';
import JoinScanner from './components_old/joinscanner';
import EventRoomJoinedCam from './components/EventRoomJoinedCam';
import Qrcodezone from './components_old/Qrcodezone';
import WelcomeScreen from './components_old/WelcomeScreen';
import NotFound from './components_old/NotFound';
import ChatPage from './components_old/ChatPage';
import { TranslationProvider } from './utils/TranslationContext';
import LanguageSwitcher from './utils/LanguageSwitcher';


// Initialize Socket.io connection
// const socket = io(process.env.REACT_APP_SOCKET_URL);
const socket = io.connect("https://wp.tpvai.com");

console.log("Socket Connection Established:", socket);

const weburl = process.env.REACT_APP_WEB_URL;
const apiurl = process.env.REACT_APP_API_URL;
const version = process.env.REACT_APP_VERSION;

console.log("Web URL:", weburl);
console.log("API URL:", apiurl);
console.log("Version:", version);

const JoinRoomHandler = () => {
  const { roomName, username } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (roomName && username) {
      // Join the room directly
      socket.emit("join-room", {
        username: username,
        roomName: roomName,
        nickName: username
      });
      
      // Store room info
      localStorage.setItem('roomid', roomName);
      
      // Navigate to chat
      navigate('/chat', { 
        state: { 
          username: username,
          roomName: roomName,
          fromUrl: true 
        }
      });
    }
  }, [roomName, username, navigate]);

  return null;
}


function App() {
  const [username, setUsername] = useState(localStorage.getItem("userId") || "");
  const [isLoggedIn, setIsLoggedIn] = useState(!!username);

  const handleLogin = (username) => {
    setIsLoggedIn(true);
    setUsername(username);
    let HashEmailid = username + "@" + Math.floor(10000 + Math.random() * 90000);
    localStorage.setItem("userId", HashEmailid); // Store username in localStorage
  };


  
  useEffect(() => {
    return () => {
      // socket.disconnect();
    };
  }, []);

  return (
    <TranslationProvider>
      <Router>
        <Routes>
          {/* Default Route: Redirect based on login status */}
          <Route
            path="/"
            element={isLoggedIn ? <Navigate to="/main" /> : <EnterEmail onLogin={handleLogin} />}
          />

          {/* Main Routes */}
          <Route path="/main" element={<Main />} />
          <Route path="/welcome" element={<Welcome />} />
          {/* <Route path="/event" element={<Event />} /> */}
          <Route
          path="/event"
          element={
            <ProtectedRoute>
              <Event socket={socket} />
            </ProtectedRoute>
          }
        />
          <Route path="/event/tvguid" element={<Tvguid />} />
          <Route path="/event/tvguid/channelinfo" element={<ChannelInfo />} />
          <Route path="/event/rooms" element={<MainWatchRoom socket={socket}/>} />
          <Route path="/event/info" element={<ChannelInfo />} />
          <Route 
          path="/join/:roomName/:username" 
          element={<JoinRoomHandler />} 
        />
          {/* Additional Routes with Socket Integration */}
          <Route path="/chat" element={<ChatPage username={username} socket={socket} />} />
          <Route path="/letchat" element={<WelcomeScreen onLogin={handleLogin} socket={socket} />} />
          <Route path="/joinscan" element={<JoinScanner socket={socket} />} />
          <Route path="/joinevent" element={<EventRoomJoinedCam socket={socket} />} />
          {/* <Route path="/current/welcome" element={<Qrcodezone socket={socket} />} /> */}
          <Route
          path="/current/welcome"
          element={
            <ProtectedRoute>
              <Qrcodezone socket={socket} />
            </ProtectedRoute>
          }
        />

          {/* Fallback Route */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </TranslationProvider>
  );
}

export default App;
