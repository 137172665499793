// import React, { useEffect,useState} from "react";
// import {useNavigate,useLocation } from "react-router-dom";
// import ShowInfo from './ShowInfo';
// import GroupList from './GroupList';
// import ChatPanel from './ChatPanel';
// import './styles.css';


// const users = [
//   { id: '1', name: 'Sonia', initial: 'S', color: '#ec4899', isOnline: true },
//   { id: '2', name: 'Roger', initial: 'R', color: '#3b82f6', isHost: true, isOnline: false },
//   // { id: '3', name: 'Viraj', initial: 'V', color: '#3b82f6', isOnline: true },
// ];

// const messages = [
//   { id: '1', userId: '2', text: 'See you at show time!', timestamp: new Date() }
//   // ,{ id: '1', userId: '1', text: 'See you at show time!', timestamp: new Date() },
// ];

// function MainWatchRoom() {
//     const navigate = useNavigate();
//   const location = useLocation();
//    const [rooms, setRooms] = useState([]);
//     const [roomDetail, setRoomDetail] = useState();
//   const roomId = location.state?.roomId || {};
  
//   useEffect(() => {
//     if (roomId) {
//       console.log("roomId Details:", roomId);
//       setRooms(roomId)
//       fetch(`https://watchparty.tpvai.com/b/api/getWatchRoom/${roomId}`)
//       .then((response) => response.json())
//       .then((data) => {
//         setRoomDetail(data); // Store the fetched rooms data in state
//       })
//     } else {
//       console.log("No matching program found.");
//     }
//   }, []);

//   // return (
//   //   <div className="app-w">
//   //     <div className="main-content">
//   //       <h1 className="room-title">rooms</h1>
//   //       <ShowInfo />
//   //     </div>
//   //     <div className="sidebar">
//   //       <GroupList users={users} />
//   //       <ChatPanel messages={messages} users={users} />
//   //     </div>
//   //   </div>
//   // );
//   return (
//     <div className="app-w">
//       <div className="main-content">
//       <button onClick={() =>  navigate('/event')}>back</button>
//         <h1 className="room-title">Room Details</h1>
//         {roomId ? (
//           // Display roomId as a string
//           typeof roomId === "object" ? (
//             <pre>{JSON.stringify(roomId, null, 2)}</pre> // Nicely formatted JSON
//           ) : (
//             <p>Room ID: {roomId}</p>
//           )
//         ) : (
//           <p>No Room ID available.</p>
//         )}
//         <ShowInfo />
//       </div>
//       <div className="sidebar">
//         <GroupList users={users} />
//         <ChatPanel messages={messages} users={users} />
//       </div>
//     </div>
//   );
// }

// export default MainWatchRoom;


// new api data
// import React, { useEffect, useState } from "react";
// import { useNavigate, useLocation } from "react-router-dom";
// import ShowInfo from "./ShowInfo";
// import GroupList from "./GroupList";
// import ChatPanel from "./ChatPanel";
// import "./styles.css";

// function MainWatchRoom() {
//   const navigate = useNavigate();
//   const location = useLocation();
//   const [roomDetail, setRoomDetail] = useState(null);
//   const roomId = location.state?.roomId || null;

//   useEffect(() => {
//     if (roomId) {
//       fetch(`https://watchparty.tpvai.com/b/api/getWatchRoom/${roomId}`)
//         .then((response) => response.json())
//         .then((data) => {
//           setRoomDetail(data); // Store the fetched room data in state
//         })
//         .catch((error) => console.error("Error fetching room details:", error));
//     } else {
//       console.log("No room ID provided.");
//     }
//   }, [roomId]);

//   // Transform participants for GroupList
//   const participants =
//     roomDetail?.participants?.map((participant) => ({
//       id: participant.userId,
//       name: participant.userId,
//       initial: participant.userId.charAt(0).toUpperCase(),
//       color: "#3b82f6", // You can customize colors here
//       isOnline: true, // Default to online; update with real status if available
//     })) || [];

//   return (
//     <div className="app-w">
//       <div className="main-content">
//         <button onClick={() => navigate("/event")}>Back</button>
//         {/* <h1 className="room-title">Room Details</h1> */}
//         {roomDetail ? (
//           <>
//           <h1 className="room-title">{roomDetail.roomName}</h1>
//             {/* <p>Room Name: {roomDetail.roomName}</p>
//             <p>Host User: {roomDetail.hostUserId}</p>
//             <p>Max Participants: {roomDetail.maxParticipants}</p>
//             <p>
//               Created At: {new Date(roomDetail.createdAt).toLocaleString()}
//             </p> */}
//           </>
//         ) : (
//           <p>Loading room details...</p>
//         )}
//         <ShowInfo qrUrl={roomDetail?.qrUrl} />
//       </div>
//       <div className="sidebar">
//         <GroupList users={participants} />
//         <ChatPanel messages={[]} users={participants} />
//       </div>
//     </div>
//   );
// }

// export default MainWatchRoom;




// new
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ShowInfo from "./ShowInfo";
import GroupList from "./GroupList";
import ChatPanel from "./ChatPanel";
import "./stylesInfo.css";
import QRCode from "qrcode";

// +1
const generateRandomString = (length) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }
  return result;
};

function MainWatchRoom({socket}) {
  const navigate = useNavigate();
  const location = useLocation();
  const [roomDetail, setRoomDetail] = useState(null);
  const roomId = location.state?.roomId || null;

  // +2
   const [eventData, setEventdata] = useState(null)
    const [showMessage, setShowMessage] = useState(false);
    const [showtempdata, setShowtempdata] = useState("");
    const [qrCodeImageUrl, setQrCodeImageUrl] = useState();
    const [tvConnectionData, setTVConnectionData] = useState(null);

    // +3
  // const serverUrl = "http://localhost:3000";
  const serverUrl = process.env.REACT_APP_SOCKET_URL;
  const conv = "/" + generateRandomString(6);
  const Joinedurl = serverUrl + conv;

    // +4
   useEffect(() => {
      console.log("useEffect running...");
      const generateQRCode = (token, sessionId) => {
        const additionalData = {
          macId: "8C-21-4A-9F-20-C1",
          room_id: {
            roomId: "93a2423f-b06e-43ca-8e76-244fa4e5a819",
            isExpired: false,
            showTime: {
              title: "Mirette ermittelt_join2",
              start: "1670322000000",
              end: "1670322600000"
            }
          },
          type: "joined"
        };
  
        const url = `${serverUrl}/#/joinevent?token=${token}&sessionId=${sessionId}&joinedurl=${Joinedurl}&data=${encodeURIComponent(JSON.stringify(additionalData))}`;
        setShowtempdata(url);
        console.log("url", url);
  
        // const urltemp = `${serverUrl}/#/joinevent?token=${token}&sessionId=${sessionId}&joinedurl=${Joinedurl}`;
        QRCode.toDataURL(url, (err, qrCodeUrl) => {
          if (err) {
            console.error('Error generating QR code:', err);
            return;
          }
          setQrCodeImageUrl(qrCodeUrl);
          console.log("qrcodeurlllllllll", qrCodeUrl);
          socket.emit('qrCodeScanned', { data: url });
        });
      };
  
      const generateTokenAndSessionId = () => {
        const token = generateRandomString(10);
        const sessionId = generateRandomString(10);
        return { token, sessionId };
      };
  
      const { token, sessionId } = generateTokenAndSessionId();
      console.log('token', token);
      console.log('session', sessionId);
      generateQRCode(token, sessionId);
  
    }, [navigate, socket]);


      // +5
      useEffect(() => {
        socket.on("TVconnected", function (response) {
          console.log("Socket response:", response);
          // Store the response data in the state with the proper format
          setTVConnectionData(response.data);
          setEventdata(JSON.stringify(response.data.additionalData, null, 2))
          setShowMessage(true);
        });
      }, [socket]);

  useEffect(() => {
    if (roomId) {
      fetch(`https://watchparty.tpvai.com/b/api/getWatchRoom/${roomId}`)
        .then((response) => response.json())
        .then((data) => {
          setRoomDetail(data); // Store the fetched room data in state
        })
        .catch((error) => console.error("Error fetching room details:", error));
    } else {
      console.log("No room ID provided.");
    }
  }, [roomId]);

  // Transform participants for GroupList
  const participants =
    roomDetail?.participants?.map((participant) => ({
      id: participant.userId,
      name: participant.userId,
      initial: participant.userId.charAt(0).toUpperCase(),
      color: "#3b82f6", // You can customize colors here
      isOnline: true, // Default to online; update with real status if available
    })) || [];

  return (
    <div className="app-w">
      <div className="main-content">
      <h1 className="room-title">Room Details</h1>
        <button onClick={() => navigate("/event")}>Back</button>
        {roomDetail ? (
          <ShowInfo roomDetail={roomDetail} socket={socket}  />
        ) : (
          <p>Loading room details...</p>
        )}
      </div>
      <div className="sidebar">
        <GroupList users={participants} />
        <ChatPanel messages={[]} users={participants} />
      </div>
    </div>
  );
}

export default MainWatchRoom;
